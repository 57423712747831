import React from 'react';
import dynamic from 'next/dynamic';
import clientIP from 'utils/clientIP';
import Input from '../input';
import Button from '../button';

import Recaptcha from 'react-google-recaptcha';
import {
  FooterMessage,
  LeadFormStyled,
  SecurityPlaceMessageBox,
  TitleContainer,
} from './styles';
import { sendLeadForm } from 'actions/lead';

import CartContext from 'contexts/CartContext';
import scrollTo from 'utils/scrollTo';
import { parsePhoneNumber } from 'react-phone-number-input';
import { validatePhone } from 'utils/PhoneNumberValidation';
import PhoneNumberInput from 'componentsV2/PhoneNumberInput';
import router from 'next/router';
import { formatWspLiveLink, getTagManagerProduct } from 'utils/globalFunctions';
import Steps from 'componentsV2/Steps';
import ErrorBox from 'componentsV2/ErrorBox';
import Checkbox from 'componentsV2/Checkbox';
import PoliticsAndTerms from 'componentsV2/PoliticsAndTerms';
import { createLead } from 'services/repositories';
import { getErrorMessage } from 'utils/errors';
import { CAPTCHA_TOKEN_STAGING } from 'config/captchaStaging';
import TagManager from 'react-gtm-module';
import { getAnalyticsGclid } from 'utils/getAnalyticsGclid';
import * as validate from 'utils/inputValidate';
import ReturnFirstCapital from 'utils/returnFirstCapital';
import { sendDataToAnalytics } from 'utils/sendDataToAnalytics';
import { getProductV3CaptchaKey } from 'utils/getProductCaptchaKey';

const DsTextField = dynamic(() => import('componentsV2/DS/TextField'), {
  ssr: false,
});

const DsTypography = dynamic(() => import('componentsV2/DS/Typography'), {
  ssr: false,
});

const DsButton = dynamic(() => import('componentsV2/DS/Button'), {
  ssr: false,
});

const DsCheckbox = dynamic(() => import('componentsV2/DS/Checkbox'), {
  ssr: false,
});

const DsIcon = dynamic(() => import('componentsV2/DS/Icon'), {
  ssr: false,
});
class LeadForm extends React.Component {
  state;
  static contextType = CartContext;

  constructor(props) {
    super(props);

    this.handlePhoneInputBlur = this.handlePhoneInputBlur.bind(this);

    this.state = {
      captchaRef: React.createRef(),
      disableButton: false,
      errorMessage: null,
      startPulseAnimation: false,
      maskPhone: '?9 99999-9999999',
      isPhoneNumberEmpty: false,
      maskCpf: '*??99???99?',
      captchaToken: null,
      data: null,
      links: null,
      politicsChecked: false,
      inputs: {
        name: {
          value: null,
          errorMessage: null,
        },
        email: {
          value: null,
          errorMessage: null,
        },
        phone: {
          value: null,
          errorMessage: null,
        },
      },
      ipv4: '',
      ipv6: '',
      captchaValidateCount: 0,
    };
  }

  async componentDidMount() {
    const tagManagerArgs = {
      dataLayer: {
        event: 'virtualPageview',
        page_url: router?.pathname,
        product: getTagManagerProduct(
          this.context?.productTheme,
          this.context?.productSlug
        ),
      },
    };

    TagManager.dataLayer(tagManagerArgs);

    if (this.props?.query?.route === 'direct-payment')
      this.context.setLoading(true);

    this.setState({
      links:
        this.context?.environment?.toUpperCase() == 'TEST'
          ? this.context.productTheme?.data?.links?.test
          : this.context.productTheme?.data?.links?.production,
    });

    this.updateInputsState({
      inputId: 'phone',
      param: 'value',
      value: this.context?.userPhone,
    });

    if (this.context.arrayQuery?.data) this.getURLData();
    this.getIP();

    if (this.props?.query?.contactId) {
      this.setState((prev) => {
        return {
          ...prev,
          politicsChecked:
            this.props?.query?.route === 'direct-payment' ? true : false,
        };
      });

      const inputPropValues = [
        {
          inputId: 'name',
          value: this.props?.values?.name,
        },
        {
          inputId: 'email',
          value: this.props?.values?.email,
        },
        {
          inputId: 'phone',
          value: this.props?.values?.phone,
        },
      ];

      inputPropValues?.forEach((input) => {
        this.updateInputsState({
          inputId: input?.inputId,
          value: input?.value,
          param: 'value',
        });
      });

      if (this.props?.query?.route === 'direct-payment') {
        setTimeout(() => {
          this.proceed();
        }, 500);
      }
    }
  }

  async getIP() {
    const objIPs = await clientIP();
    return this.setState(objIPs);
  }

  formatValueOnChange({ inputName, inputValue }) {
    let formattedValue = inputValue;

    if (inputName === 'email') {
      formattedValue = inputValue?.replace(/ /g, '')?.trim()?.toLowerCase();
    } else if (inputName === 'name') {
      const splittedValue = inputValue?.replace(/\s{2,}/g, ' ')?.split(' ');
      formattedValue = splittedValue
        ?.map((item) => ReturnFirstCapital(item))
        ?.join(' ')
        ?.toString();
    }

    return formattedValue;
  }

  updateInputsState({ inputId, value, param }) {
    this.setState((prev) => ({
      ...prev,
      inputs: {
        ...prev?.inputs,
        [inputId]: {
          ...prev?.inputs[inputId],
          [param]: value,
        },
      },
    }));
  }

  getInputsErrors({ inputId, inputType, inputValue }) {
    const valid = this.validateInput({
      fieldtype: inputType?.toUpperCase(),
      value: inputValue,
      lang: this.context?.productTheme?.data?.lang,
      product: this.context?.product,
    });

    this.updateInputsState({
      inputId,
      value: valid?.errorMessage,
      param: 'errorMessage',
    });

    return valid;
  }

  getURLData() {
    try {
      this.context?.setLoading(true);

      const data = JSON.parse(atob(this.context.arrayQuery?.data));

      if (data?.sf_id) this.setState({ sfId: data.sf_id });
      if (data?.name) {
        this.updateInputsState({
          inputId: 'name',
          param: 'value',
          value: data.name,
        });
      }
      if (data?.email) {
        this.updateInputsState({
          inputId: 'email',
          param: 'value',
          value: data.email,
        });
      }
      if (data?.phone) {
        this.updateInputsState({
          inputId: 'phone',
          param: 'value',
          value: data.phone,
        });
      }
      if (data?.name && data?.email && data?.phone) {
        this.proceed();
      }
    } catch (err) {
      console.log(err);
    }
  }

  proceed(e) {
    e?.preventDefault();
    e?.stopPropagation();
    const isTestEnvironment =
      this.context?.environment?.toUpperCase() === 'TEST';

    if (this.state.disableButton) {
      return null;
    }
    const invalidInput = this.validateForm();

    if (invalidInput) {
      if (!invalidInput.phone) {
        scrollTo(invalidInput.offsetTop);
      }

      if (!this.state?.inputs?.phone?.value) {
        this.setState({
          isPhoneNumberEmpty: true,
        });
      } else {
        this.setState({
          isPhoneNumberEmpty: false,
        });
      }
    } else {
      if (!this.state.politicsChecked) {
        this.setState({
          startPulseAnimation: true,
          showCallCenterLink: false,
          errorTitle: 'Politica de Privacidade',
          errorMessage:
            'Para prosseguir, aceite a Política de Privacidade no checkbox acima do botão.',
          disableButton: false,
        });
      } else {
        this.context.setLoading(true);
        this.setState({ disableButton: true, errorMessage: null });

        sendDataToAnalytics(
          {
            userEmail: this.state?.inputs?.email?.value?.toLowerCase() || null,
            source: router.query.source,
            seller: router.query.seller,
            system: this.context.productBusiness,
            _ga: this.context?._ga,
          },
          'lead',
          'click'
        );

        if (isTestEnvironment) {
          this.sendForm(CAPTCHA_TOKEN_STAGING);
        } else {
          this.state.captchaRef.current.execute();
        }
      }
    }
  }

  sendForm(captchaToken) {
    const msJanaCustomFlowId = '478dc309-f90c-4e17-86b5-44b189cbe1c7';

    const form = {
      email: this.state.inputs?.email?.value?.toLowerCase(),
      name: this.state.inputs?.name?.value,
      phone: this.state.inputs?.phone?.value,
      sfId: this.state?.sfId,
      source: router.query.source,
      seller: router.query.seller,
      system: this.context.productBusiness,
      product:
        router.query.voucher !== 'msjana'
          ? this.context?.productId
          : msJanaCustomFlowId,
      route: this.context?.route,
      voucher:
        router.query.voucher !== 'msjana' ? router.query.voucher : undefined,
      voucherValidate: this.context.cookiePartner ? true : undefined,
      captchaToken,
      ipv4: this.state.ipv4,
      ipv6: this.state.ipv6,
      analytics: { gclid: getAnalyticsGclid() },
      contactId: this.context?.contactId,
      planId:
        this.context?.product === 'MS'
          ? process.env.MS_PIX_SALES_ID
          : undefined,
    };

    const productIds = {
      ms: 'meusucesso',
      wup: 'wiseup-online',
      em: this.context?.productSlug,
      powerhouse: 'powerhouse',
    };

    const unavailableRoutesOnMercury = ['pix', 'livelo', 'bankslip'];

    // Será removido quando powerhouse for migrado para o DS
    if (
      this.context?.isMozambiqueTheme ||
      !unavailableRoutesOnMercury.includes(this.context?.route) ||
      this.context?.product === 'POWERHOUSE'
    ) {
      const operation = this.context?.isMozambiqueTheme ? 'MZ' : 'BR';

      const productsWithBusiness = ['POWERHOUSE', 'MS'];
      const product = productIds[this.context.product?.toLowerCase()];

      const bffData = {
        email: form.email,
        name: form.name,
        phone: form.phone,
        source: form.source,
        seller: form.seller,
        voucher: router.query.voucher,
        operation,
        plan: router.query?.voucher === 'msjana' ? 'extended' : 'default',
        route: ['em', 'ms'].includes(this.context.product?.toLowerCase())
          ? undefined
          : form?.route || 'payment',
        captchaToken:
          this.context?.environment?.toUpperCase() == 'TEST'
            ? CAPTCHA_TOKEN_STAGING
            : form.captchaToken,
        analytics: { gclid: getAnalyticsGclid() },
        planType: 'recurrency',
        business: product,
        productCode:
          this.context?.productSlug || this.context.productCode || product,
      };

      createLead(bffData)
        .then((response) => {
          const tagManagerArgs = {
            dataLayer: {
              event: 'generate_lead',
              phone_number: form?.phone,
              email: form?.email,
              product: getTagManagerProduct(
                this.context?.productTheme,
                this.context?.productSlug
              ),
            },
          };

          TagManager.dataLayer(tagManagerArgs);

          this.props.onSubmitSuccess(response, bffData);
        })
        .catch((error) => {
          console.log('error: ', error);

          const showCallCenterLink =
            error?.response?.data?.messageCode === 'ACCOUNT_ALREADY_EXISTS';

          this.setState({
            showCallCenterLink,
            errorTitle: showCallCenterLink ? 'Esta conta já existe' : undefined,
            errorMessage: getErrorMessage(
              operation,
              error?.response?.data?.messageCode
            ),
            disableButton: false,
          });
          this.context.setLoading(false);
        })
        .finally(() => {
          if (this.context?.environment?.toUpperCase() !== 'TEST') {
            this.state.captchaRef.current?.reset();
          }
        });
    } else {
      sendLeadForm(form)
        .then((response) => {
          const backupForm = JSON.parse(
            sessionStorage.getItem('dataForm') || null
          );
          if (
            this.state.inputs?.email?.value?.toLowerCase() !==
            backupForm?.email?.toLowerCase()
          ) {
            sessionStorage.removeItem('pixLead');
          }

          const tagManagerArgs = {
            dataLayer: {
              event: 'generate_lead',
              phone_number: form?.phone,
              email: form?.email,
              product: getTagManagerProduct(
                this.context?.productTheme,
                this.context?.productSlug
              ),
            },
          };
          TagManager.dataLayer(tagManagerArgs);

          if (this.props.onSubmitSuccess != null)
            this.props.onSubmitSuccess(response, form);
          else if (response.message) {
            const existingWolSubscription =
              'Já existe uma conta criada com este e-mail. Caso tenha dúvidas sobre sua assinatura, acesse nossa Home e fale com um de nossos atendentes na aba Ajuda.';

            if (
              response.message === existingWolSubscription &&
              this.props?.query?.route === 'direct-payment'
            ) {
              router.push(formatWspLiveLink(this.context?.environment));
            }

            const showCallCenterLink =
              response.message === existingWolSubscription;

            this.setState({
              showCallCenterLink,
              errorTitle: showCallCenterLink
                ? 'Esta conta já existe'
                : undefined,
              errorMessage: response?.message,
              disableButton: false,
            });
          }
        })
        .catch((err) => {
          const existingWolSubscription =
            'Já existe uma conta criada com este e-mail. Caso tenha dúvidas sobre sua assinatura, acesse nossa Home e fale com um de nossos atendentes na aba Ajuda.';

          if (
            err?.message === existingWolSubscription &&
            this.props?.query?.route === 'direct-payment'
          ) {
            router.push(formatWspLiveLink(this.context?.environment));
          }

          const showCallCenterLink = err?.messageCode
            ? err?.messageCode === 'ACCOUNT_ALREADY_EXISTS'
            : err.message === existingWolSubscription;

          this.setState({
            showCallCenterLink,
            errorTitle: showCallCenterLink ? 'Esta conta já existe' : undefined,
            errorMessage: err?.messageCode
              ? getErrorMessage('BR', err?.messageCode)
              : err?.message || this.context.productTheme?.text?.error?.general,
            disableButton: false,
          });

          scrollTo(270);

          this.context.setLoading(false);
        });
    }
  }

  validateForm() {
    let firstInvalidInput = false;

    for (const key in this.state?.inputs) {
      let valid = {
        phone: false,
      };
      let field = null;

      field = document.getElementById(key);

      if (key === 'phone') {
        const number = this.state?.inputs[key]?.value || null;

        valid.valid =
          number !== null &&
          !!validatePhone(number, parsePhoneNumber(number)?.country);

        valid.phone = true;

        if (!valid.valid) {
          this.updateInputsState({
            inputId: key,
            value:
              'Insira o DDD e um número de celular válido. Caso seja de fora do Brasil, selecione a bandeira no ícone ao lado.',
            param: 'errorMessage',
          });
        }
      } else {
        if (field) {
          valid = this.getInputsErrors({
            inputId: key,
            inputType: key,
            inputValue: field?.value,
          });
        }
      }

      if (valid.valid === false) {
        this.setState({ startPulseAnimation: true });

        if (!valid.phone) {
          firstInvalidInput = field;
        } else {
          firstInvalidInput = valid;
        }
      }
    }

    return firstInvalidInput;
  }

  formatPhone(e) {
    const val = e.target.value;
    if (val.startsWith('+')) {
      this.setState({ maskPhone: '?99999999999999' });
    } else {
      this.setState({ maskPhone: '?9 99999-9999999' });
    }
  }

  getLeadTextObject() {
    if (this.context.productFlowType === 'TOCO')
      return this.context.productTheme?.text?.lead?.toco;
    else return this.context.productTheme?.text?.lead;
  }

  handleCheckPolitics(value) {
    this.setState({ politicsChecked: value });
  }

  validateInput({ fieldtype, value, lang, product }) {
    const validateObj = validate.inputValidate(fieldtype, value, lang, product);

    return validateObj;
  }

  handlePhoneInputBlur() {
    const phoneNumberValue = this.state.inputs.phone.value;

    const isPhoneValid = !!validatePhone(
      phoneNumberValue,
      parsePhoneNumber(phoneNumberValue || '')?.country
    );

    if (isPhoneValid) {
      this.updateInputsState({
        inputId: 'phone',
        value: null,
        param: 'errorMessage',
      });
    }
  }

  render() {
    const lang = this.context.productTheme.data.lang;
    const placeholder = this.context.productTheme?.text?.lead?.placeholder;

    const defaultCountryLangs = {
      'pt-BR': 'BR',
      'es-MX': 'MX',
      'pt-MZ': 'MZ',
    };

    const leadTextObject = this.getLeadTextObject();

    return (
      <LeadFormStyled hideForm={this.props?.hideForm} className="lead-form">
        <div className="titleActionLead">
          <TitleContainer>
            <DsTypography
              data-cy="@lead/page-title-message"
              dangerouslySetInnerHTML={{
                __html: leadTextObject?.text,
              }}
              component="heading"
              size="medium"
              color="neutral-pure"
              font={this.context?.product === 'MS' ? 'secondary' : 'primary'}
            />
            {!!leadTextObject?.description && (
              <DsTypography
                data-cy="@lead/page-description-message"
                dangerouslySetInnerHTML={{
                  __html: leadTextObject?.description,
                }}
                component="paragraph"
                size="medium"
                color="neutral-high"
              />
            )}
          </TitleContainer>
        </div>

        <Steps
          data-cy="@lead/steps-box"
          stepsNumber={this.context?.productTheme?.data?.stepsNumber || 4}
          currentStep={1}
        />

        <form
          id="lead"
          action={this.props.action}
          method={this.props.method}
          onSubmit={(e) => this.proceed(e)}
        >
          {this.state.errorMessage && (
            <ErrorBox
              showCallCenterLink={this.state?.showCallCenterLink}
              errorTitle={this.state.errorTitle}
              errorMessage={this.state.errorMessage}
              onClose={() =>
                this.setState({ ...this.state, errorMessage: null })
              }
            />
          )}

          {!this.context?.isMozambiqueTheme ? (
            <>
              <DsTextField
                onChange={(event) =>
                  this.updateInputsState({
                    inputId: event?.target?.id,
                    value: this.formatValueOnChange({
                      inputName: event?.target?.id,
                      inputValue: event?.target?.value,
                    }),
                    param: 'value',
                  })
                }
                onBlur={(event) => {
                  if (event?.target?.value) {
                    this.getInputsErrors({
                      inputId: event?.target?.id,
                      inputType: event?.target?.type,
                      inputValue: event?.target?.value,
                    });
                  }
                }}
                id="name"
                label={this.context?.productTheme?.text?.lead?.labels?.name}
                placeholder={
                  this.context?.productTheme?.text?.lead?.placeholder?.name
                }
                value={
                  this.state?.inputs?.name?.value
                    ? this.state?.inputs?.name?.value
                    : this.props.values
                      ? this.props.values?.name
                      : this.context?.userName || ''
                }
                helperText={this.state?.inputs?.name?.errorMessage}
                name="name"
                type="name"
                autoComplete="name"
                data-cy="@lead/fullname-input"
                dataCyErrorMessage="@lead/errorfullname-message"
                maxLength={200}
              />
              <DsTextField
                onChange={(event) =>
                  this.updateInputsState({
                    inputId: event?.target?.id,
                    value: this.formatValueOnChange({
                      inputName: event?.target?.id,
                      inputValue: event?.target?.value,
                    }),
                    param: 'value',
                  })
                }
                onBlur={(event) => {
                  if (event?.target?.value) {
                    this.getInputsErrors({
                      inputId: event?.target?.id,
                      inputType: event?.target?.type,
                      inputValue: event?.target?.value,
                    });
                  }
                }}
                id="email"
                label={this.context?.productTheme?.text?.lead?.labels?.email}
                placeholder={
                  this.context?.productTheme?.text?.lead?.placeholder?.email
                }
                value={
                  this.state?.inputs?.email?.value
                    ? this.state?.inputs?.email?.value
                    : this.props.values
                      ? this.props.values?.email
                      : this.context.userEmail || ''
                }
                helperText={this.state?.inputs?.email?.errorMessage}
                name="email"
                type="email"
                autoComplete="email"
                data-cy="@lead/email-input"
                dataCyErrorMessage="@lead/erroremail-message"
                maxLength={80}
              />
            </>
          ) : (
            <>
              <Input
                onBlur={() => {
                  if (this.state?.inputs?.name?.value) {
                    this.getInputsErrors({
                      inputId: 'name',
                      inputType: 'name',
                      inputValue: this.state?.inputs?.name?.value,
                    });
                  }
                }}
                onChange={(value) => {
                  this.updateInputsState({
                    inputId: 'name',
                    value: value,
                    param: 'value',
                  });
                }}
                value={
                  this.props?.values
                    ? this.props?.values?.name
                    : this.context?.userName || ''
                }
                fieldName={'name'}
                fieldtype={'NAME'}
                fieldLabel={this.context.productTheme?.text?.lead?.labels?.name}
                inputPlaceholder={
                  this.context.productTheme?.text?.lead?.placeholder?.name
                }
                errorMessage={this.state?.inputs?.name?.errorMessage}
                autocomplete={'name'}
                htmlInputType={'text'}
                dataCy="@lead/fullname-input"
                dataCyErrorMessage="@lead/errorfullname-message"
                maxLength={200}
              />
              <Input
                onBlur={() => {
                  if (this.state?.inputs?.email?.value) {
                    this.getInputsErrors({
                      inputId: 'email',
                      inputType: 'email',
                      inputValue: this.state?.inputs?.email?.value,
                    });
                  }
                }}
                onChange={(value) => {
                  this.updateInputsState({
                    inputId: 'email',
                    value: value,
                    param: 'value',
                  });
                }}
                value={
                  this.props.values
                    ? this.props.values?.email
                    : this.context.userEmail || ''
                }
                fieldName={'email'}
                fieldtype={'EMAIL'}
                fieldLabel={
                  this.context.productTheme?.text?.lead?.labels?.email
                }
                inputPlaceholder={
                  this.context.productTheme?.text?.lead?.placeholder?.email
                }
                errorMessage={this.state?.inputs?.email?.errorMessage}
                autocomplete={'email'}
                htmlInputType="email"
                dataCy="@lead/email-input"
                dataCyErrorMessage="@lead/erroremail-message"
                maxLength={80}
              />
            </>
          )}

          <PhoneNumberInput
            value={
              this.state?.inputs?.phone?.value
                ? this.state?.inputs?.phone?.value
                : this.props.values
                  ? this.props.values?.phone
                  : this.context.userPhone || ''
            }
            fieldtype={'PHONE'}
            onChange={(phoneNumber) => {
              this.updateInputsState({
                inputId: 'phone',
                value: phoneNumber,
                param: 'value',
              });
            }}
            onBlur={this.handlePhoneInputBlur}
            hasError={this.state.isPhoneNumberEmpty}
            helperText={this.state?.inputs?.phone.errorMessage}
            label={this.context.productTheme?.text?.lead?.labels?.phone}
            dataCy="@lead/cellphone-input"
            dataCyErrorMessage="@lead/errorcellphone-message"
            defaultCountry={defaultCountryLangs[lang] || 'BR'}
            placeholder={placeholder?.phone || 'Ex: 41 99999 9999'}
          />

          {!this.context?.isMozambiqueTheme ? (
            <DsCheckbox
              required
              id="politics-checkbox"
              checked={this.state.politicsChecked}
              data-testid="checkbox"
              pulse={this.state?.startPulseAnimation}
              onChange={({ target }) =>
                this.handleCheckPolitics(JSON.parse(target?.checked))
              }
              data-cy="@lead/agreewithprivacypolicy-checkbox"
            >
              <PoliticsAndTerms
                product={this.context?.product}
                isMz={this.context?.isMozambiqueTheme}
                politicsUrl={
                  this.context?.productTheme?.data?.links[
                    this.context?.environment
                  ]?.politics
                }
              />
            </DsCheckbox>
          ) : (
            <div data-cy="@lead/checkbox-box" className="box-check-politica">
              <Checkbox
                startPulseAnimation={this.state?.startPulseAnimation}
                dataCy="@lead/agreewithprivacypolicy-checkbox"
                onChange={({ target }) =>
                  this.handleCheckPolitics(target?.checked)
                }
              >
                <PoliticsAndTerms
                  product={this.context?.product}
                  isMz={this.context?.isMozambiqueTheme}
                  politicsUrl={
                    this.context?.productTheme?.data?.links[
                      this.context?.environment
                    ]?.politics
                  }
                />
              </Checkbox>
            </div>
          )}
          {!this.context?.isMozambiqueTheme ? (
            <DsButton
              id="#LeadForm/continue-button"
              testId="continue-button"
              data-cy="@lead/continue-button"
              disabled={this.state.disableButton}
              buttonType={'submit'}
              onClick={(e) => this.proceed(e)}
            >
              {this.context.productTheme?.text?.lead?.button}
            </DsButton>
          ) : (
            <Button
              testId="continue-button"
              id={'continue-button'}
              buttonType={'submit'}
              onClick={(e) => this.proceed(e)}
              title={this.context.productTheme?.text?.lead?.button}
              disabled={this.state.disableButton}
              dataCy="@lead/continue-button"
            />
          )}
        </form>

        <SecurityPlaceMessageBox
          product={this.context?.product}
          data-cy="@lead/sucurity-place-message"
        >
          <DsIcon icon="shield_check" />
          <DsTypography component="paragraph" size="small" color="neutral-high">
            Ambiente
            <DsTypography component="paragraph" size="small-bold">
              100%
            </DsTypography>
            seguro
          </DsTypography>
        </SecurityPlaceMessageBox>
        <FooterMessage
          product={this.context?.product}
          data-cy="@lead/recaptcha-sucurity-message"
        >
          <DsTypography component="paragraph" size="small" color="neutral-high">
            Este site é protegido pelo reCAPTCHA e aplicam-se a{' '}
          </DsTypography>
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noreferrer"
          >
            <DsTypography
              component="paragraph"
              size="small"
              color="neutral-high"
            >
              Política de Privacidade
            </DsTypography>
          </a>{' '}
          <DsTypography component="paragraph" size="small" color="neutral-high">
            e os{' '}
          </DsTypography>
          <a
            href="https://policies.google.com/terms"
            target="_blank"
            rel="noreferrer"
          >
            <DsTypography
              component="paragraph"
              size="small"
              color="neutral-high"
            >
              Termos de Serviço
            </DsTypography>
          </a>{' '}
          <DsTypography component="paragraph" size="small" color="neutral-high">
            do Google.
          </DsTypography>
        </FooterMessage>

        <Recaptcha
          size="invisible"
          ref={this.state.captchaRef}
          onChange={(captchaToken) => {
            this.sendForm(captchaToken);
          }}
          sitekey={getProductV3CaptchaKey()}
        />
      </LeadFormStyled>
    );
  }
}

export default LeadForm;
